import robotechMovie from'../assets/robotech.mp4';

function RobotechMovie() {
    return (
      <div className="bg-white">
        <div className="mx-auto grid grid-cols-1 items-center gap-x-8 gap-y-16 px-4">
          <video className="h-full w-full rounded-lg" autoPlay muted loop>
            <source src={robotechMovie} type="video/mp4" />
            Twoja przeglądarka nie obsługuje video.
          </video>
        </div>
      </div>
    );
  }

  export default RobotechMovie

