import React, { useState } from "react";
import './App.css';
import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'

import robotechLogo from'./assets/robotechLogo.jpg';

import Popup from "./components/popupForm";
import RobotechMovie from './components/robotechMovie';
import About from './components/about';
import Logo from './components/logo';


const navigation = [
  { name: 'o zajęciach', href: '#', current: true },
  { name: 'Team', href: '#', current: false },
  { name: 'Projects', href: '#', current: false }
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function App() {
  
  const [trigger, setTrigger] = useState(0);

  const sendData = (data) => {
    setTrigger(false)
  }

  return (
    <> 
      <Popup trigger={trigger}  func={sendData}/>
      <div className="min-h-full">
        <Disclosure as="nav" className="white sticky top-0 bg-white pb-2 shadow">
          {({ open }) => (
            <>
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="flex md:flex-row flex-col items-center justify-between">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <img
                        className="md:ml-[-18px] sm:ml-0 h-54 w-200 md:mt-2 sm:mt-0 md:mt-3"
                        src={robotechLogo}
                        alt="Your Company"
                      />
                    </div>
                  </div>
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <p className="text-4xl font-bold text-[#00a5c1] md:mt-3">
                        <span className="text-lg">tel.: </span> 608 605 994
                      </p>
                    </div>
                  </div>


                  <div className="flex items-center md:mt-0 mt-3">
                    <div className="flex items-center">
                    <button 
                           onClick={() => {
                            setTrigger((trigger) => trigger + 1);
                          }}
                      class="bg-[#00a5c1] hover:bg-[#027f94] text-white font-bold pt-2 pb-3 md:px-5 px-3 rounded-full text-2xl">
                        zapisz się!
                    </button>

                    </div>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="md:hidden">
                <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                  {navigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className={classNames(
                        item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                        'block rounded-md px-3 py-2 text-base font-medium'
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
                
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        <header className="bg-white">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 py-2 lg:px-8">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900 text-center">ZAPRASZAMY NA DNI OTWARTE ROBOTECH Z BEZPŁATNYMI LEKCJAMI POKAZOWYMI!</h1>
            <p className='text-2xl text-center'>W dniach <b>01.09.2023</b> oraz <b>08.09.2023</b> serdecznie zapraszamy na niezwykłe wydarzenie - <b>Dni Otwarte ROBOTECH</b>. To niepowtarzalna okazja, aby zapoznać się z naszymi fascynującymi zajęciami, które rozwijają zainteresowanie technologią i naukami ścisłymi u dzieci i młodzieży.</p>
          </div>
        </header>
        <main>          
          <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
            <RobotechMovie />
          </div>
        </main>
        <main>
          <About />
        </main>
        <main>
          <Logo />
        </main>
      </div>
    </>
  )
}

export default App;
