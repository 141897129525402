import edisonSumo from'../assets/edisonSumo.jpg';
import robotjakToDzialaDlugopis3d from'../assets/jakToDzialaDlugopis3d.jpg';
import lutowanie from'../assets/lutowanie.jpg';
import otto from'../assets/otto.jpg';

import jakToDziala from'../assets/jakToDziala.jpg';
import edisonProgramowanie from'../assets/edisonProgramowanie.jpg';
import pradZziemniaka from'../assets/pradZziemniaka.jpg';
import poduszkowiec from'../assets/poduszkowiec.jpg';

const features = [
    { name: 'Sprawdzisz zainteresowanie dziecka:', description: 'Dni Otwarte są idealną szansą, aby dowiedzieć się, czy Twoje dziecko pasjonuje się nauką, techniką i twórczym myśleniem.' },
    { name: 'Poznasz naszą metodologię:', description: 'Dowiesz się, jakimi nowoczesnymi technologiami oraz innowacyjnymi metodami się posługujemy, aby rozwijać potencjał Twojego dziecka i otworzyć mu drzwi do fascynującego świata nauki i technologii.' },
    { name: 'Zobaczysz zajęcia na żywo:', description: 'Przyjdź i obserwuj, jak Twoje dziecko będzie aktywnie uczestniczyć w zajęciach, pracować w grupie, tworzyć projekty i rozwijać swoje umiejętności.' },
    { name: 'Poznasz nasze miejsce:', description: 'Dni Otwarte to wyjątkowa możliwość, aby zapoznać się z naszą nowoczesną przestrzenią, gdzie odbywają się zajęcia. Przekonasz się, że kładziemy duży nacisk na inspirujące otoczenie nauki.' },
    { name: 'Twórczo i wartościowo spędzony czas:', description: 'Twoje dziecko nie tylko rozwinie umiejętności techniczne, ale także spędzi czas w kreatywny i ciekawy sposób.' },
    { name: 'Poznaj program naszych zajęć', description: 'W czasie zajęć pokazowych dla dzieci rodzice dowiedzą się o szczegółach naszych kursów, poznają moduły tematyczne, metody pracy i narzędzia przez nas stosowane. Odpowiemy również na pytania dotyczące naszych zajęć.' },
  ]
  
 function About() {
    return (
      <div className="bg-white">
        <div className="mx-auto grid grid-cols-1 items-center gap-x-8 gap-y-16 px-4 py-1 sm:px-6 sm:py-2 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
          <div>
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Dlaczego warto do nas dołączyć?</h2>

            <dl className="mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-2 lg:gap-x-8">
              {features.map((feature) => (
                <div key={feature.name} className="border-t border-gray-200 pt-4">
                  <dt className="text-xl text-gray-900">{feature.name}</dt>
                  <dd className="mt-2 text-s text-gray-500">{feature.description}</dd>
                </div>
              ))}
            </dl>
          </div>
          <div className="grid grid-cols-1 grid-rows-2 gap-4 sm:gap-6 lg:gap-8 sm:grid-cols-2">
            <img
              src={edisonSumo}
              alt="Walnut card tray with white powder coated steel divider and 3 punchout holes."
              className="rounded-lg bg-gray-100"
            />
            <img
              src={robotjakToDzialaDlugopis3d}
              alt="Top down view of walnut card tray with embedded magnets and card groove."
              className="rounded-lg bg-gray-100"
            />
            <img
              src={lutowanie}
              alt="Side of walnut card tray with card groove and recessed card area."
              className="rounded-lg bg-gray-100"
            />
            <img
              src={otto}
              alt="Side of walnut card tray with card groove and recessed card area."
              className="rounded-lg bg-gray-100"
            />
            <img
              src={jakToDziala}
              alt="Side of walnut card tray with card groove and recessed card area."
              className="rounded-lg bg-gray-100"
            />
            <img
              src={edisonProgramowanie}
              alt="Side of walnut card tray with card groove and recessed card area."
              className="rounded-lg bg-gray-100"
            />
            <img
              src={pradZziemniaka}
              alt="Side of walnut card tray with card groove and recessed card area."
              className="rounded-lg bg-gray-100"
            />
            <img
              src={poduszkowiec}
              alt="Side of walnut card tray with card groove and recessed card area."
              className="rounded-lg bg-gray-100"
            />
          </div>
        </div>
      </div>
    )
  }
  
  export default About;